import Loadable from "react-loadable";
import { Loading } from "../../common/navigation";

const Usermanagement = Loadable({
  loader: () => import("./components/Usermanagement"),
  loading: Loading
});

const Setting = Loadable({
  loader: () => import("./components/Setting"),
  loading: Loading
});

const Explorer = Loadable({
  loader: () => import("./components/Explorer"),
  loading: Loading
});

const Explorer2 = Loadable({
  loader: () => import("./components/Explorer2"),
  loading: Loading
});


export const routes = [
  {
    path: "/administration/manageusers",
    exact: true,
    component: Usermanagement,
    name: "User Management"
  },
  {
    path: "/administration/settings",
    exact: true,
    component: Setting,
    name: "Setting"
  },
  {
    path: "/administration/explorer",
    exact: true,
    component: Explorer,
    name: "Explorer"
  },
  {
    path: "/administration/explorer2",
    exact: true,
    component: Explorer2,
    name: "Explorer2"
  }
];
